import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On an average day, you can find Michelle Mullenax leading Product and User Experience for the American Red Cross’s Innovation Team. But on March 31st, she’ll also be on the SuperBot stage in San Francisco. We’re excited to welcome Michelle to San Francisco and pick her brain this spring. Get to know her better before that.`}</p>
    <h3><strong parentName="h3">{`Tell me a bit about yourself?`}</strong></h3>
    <p>{`I come from the American Red Cross, where I lead conversational artificial intelligence. As part of that role, I lead a practice for any interface between the human brain and the artificial brain, including chatbots, Alexa, Google, Siri, and IVR.`}</p>
    <p>{`I first joined the Red Cross very early in my career and spent many years working in digital marketing and product before leaving to work on product in the private sector with the PwC-Google Cloud Alliance. Artificial intelligence and machine learning were on the up-and-up, and once I finished my MBA, I was pulled back to the Red Cross to join the Innovation Team. The Innovation Team already established a precedent for voice, which I was so excited about because voice is going to become a staple in public consciousness before we know it and it was clear that there is an opportunity for big impact.`}</p>
    <h3><strong parentName="h3">{`What are some of the use cases that the Red Cross turns to conversation for?`}</strong></h3>
    <p>{`There are no use cases that are off-limits because there’s always some way you can engage with your constituents. People are in need of our services, and they need channels to learn how to get help and how to give help, whether that’s recruiting volunteers or engaging with donors.`}</p>
    <p>{`Collecting life saving blood is an important part of the work that we do. We’ve prioritized blood donation as one of our use cases. We have both chat and voice options for people to schedule a blood donation and ask question about eligibility.. I give a fair bit of my time and focus to use case. We also have live use cases in disaster relief and first aid and are looking to expand our current use cases to additional channels.`}</p>
    <h3><strong parentName="h3">{`Switching gears for a bit, but do you have any advice for someone looking to get into the voice space?`}</strong></h3>
    <p>{`Spend a good amount of time understanding conversational AI, holistically,and NLP. These are things not exclusive to any one channel, and a good understanding of the technology goes a long way, even if you’re not looking to serve in a technical role.`}</p>
    <p>{`For those who are focused on voice, specifically, really understand what the UX differences are for different channels. That’s going to be the difference between someone who can build a successful product in voice and someone who can only repeat jargon. I see a lot of people reuse experiences across channels. They’ll write an experience for chatbots and think that the same experience can be replicated in voice. There is underlying technology, but each channel has its own unique UX and honoring that is important.`}</p>
    <h3><strong parentName="h3">{`What are your best practices for designing a good voice UX?`}</strong></h3>
    <p>{`Cognitive overload is real. Have you ever had a skill that just won’t shut up? I’m waiting to respond, but it just keeps going and going. Losing your place in the conversation is a real risk factor. It’s very tempting to give as much information as possible, but there’s a very low threshold to where folks will be able to stay connected with the experience. We think about that a lot because our content is very dense and very hard to cut down. Even our name, `}<em parentName="p">{`The American Red Cross`}</em>{`, has a lot of syllables, and you’d be surprised how big those syllables sound when it’s coming from an Alexa or Google home.`}</p>
    <h3><strong parentName="h3">{`What are you looking forward to the most at SuperBot?`}</strong></h3>
    <p>{`I’m looking forward to getting out of my comfort zone and learning from my peers in the technology space. Even though the Red Cross has a strong practice for conversational AI and this is something I’ve been doing in my career for while, I have my own biases, of course. I’m a UX and product person, fundamentally, and it will be great to hear from designers, devs, strategists, researchers, etc.. I’m also excited to hear about what’s happening in other industries, like financial services, telecom, retail, and the latest in analytics from Dashbot. There’s a lot going on at SuperBot that I wouldn’t be exposed to normally since I come from a very niche practice within the nonprofit sector. There are so many things that we can learn by listening to other leaders in this field.`}</p>
    <hr></hr>
    <h3>{`About the American Red Cross`}</h3>
    <p><em parentName="p">{`The American Red Cross prevents and alleviates human suffering in the face of emergencies by mobilizing the power of volunteers and the generosity of donors.​ Learn more about them`}</em>{` `}<a parentName="p" {...{
        "href": "https://www.redcross.org/about-us.html"
      }}><em parentName="a">{`here`}</em></a><em parentName="p">{`.`}</em></p>
    <p><em parentName="p">{`You can meet Michelle at`}</em>{` `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/superbot/sb2020"
      }}><em parentName="a">{`SuperBot`}</em></a>{` `}<em parentName="p">{`this spring in San Francisco. As the premier conversational AI conference, we gather leaders in this space for a day of learning and community building.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      